import './about.css';

const Splash = () => {
    return (<div className="about-page">
        <a href="/"><h1>Martingale Stochastic Process LLC</h1></a>

        <div className="company-info-container">
            <h2>Company Information</h2>
            <h3>Founders</h3>
            <a href="https://www.linkedin.com/in/garrett-kegel-5822b921a/" target="_"><p>Garrett Kegel</p></a>
            <h3>Date Founded</h3>
            <p>2021</p>
            <h3>What We Do</h3>
            <p>
                We build websites and applications with a focus on financial technology.
            </p>
        </div>
        <div className="about-spacer"></div>
        <div className="about-nav">
            <a href="portfolio"><div>Portfolio</div></a>
            <a href="order"><div>Order</div></a>
            {/* <a href="calculate"><div>Calculate</div></a> */}
        </div>
    </div>);
}

export default Splash;