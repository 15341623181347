import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

// Components

// Pages
import Splash from './Pages/Splash';
import About from './Pages/About';
import Order from './Pages/Order';
import Portfolio from './Pages/Portfolio';

// Utils
import Auth from './Utils/auth';

var uriString = 'http://localhost:4000/graphql';

if (process.env.NODE_ENV === 'production') {
  uriString = '/graphql';
}
const httpLink = createHttpLink({
  uri: uriString
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers
    }
  }
});

const client = new ApolloClient({
  connectToDevTools: false,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});


function App() {
  return (
    <div className="all">
      <div id="static"></div>
      <div id="scan"></div>
      <ApolloProvider client={client}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Splash />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/order" element={<Order />} />
            <Route exact path="/portfolio" element={<Portfolio />} />
          </Routes>
        </Router>
      </ApolloProvider>
    </div>
  );
}

export default App;
