import './order.css';

const Order = () => {
    return (<div className="order-page">
        <a href="about" className="order-title">
            <h1>Martingale Stochastic Process LLC</h1>
        </a>

        <div className="order-form-container">
            <h1>Order Form</h1>
            <p>
                To order a new website, please enter in the information below. If there
                are additional features that are needed, please feel free to enter these
                requests in the additional requests section. Thank you!
            </p>
            <form action="">
                <label for="name">Name</label>
                <input type="text" id="name" name="name" />
                <label for="address">Street Address</label>
                <input type="text" id="address" name="address" />
                <label for="city">City</label>
                <input type="text" id="city" name="city" />
                <label for="state">State</label>
                <input type="text" id="state" name="state" size="2" />
                <label for="zip">Zipcode</label>
                <input type="text" id="zip" name="zip" size="5" />
                <div className="radiobutton-container">
                    <fieldset>
                        <legend>Type of Website</legend>
                        <div>
                            <div>
                                <input
                                    type="radio"
                                    id="typeChoice1"
                                    name="websiteType"
                                    value="mern"
                                />
                                <label for="typeChoice1">MERN Stack</label>
                            </div>

                            <div>
                                <input
                                    type="radio"
                                    id="typeChoice2"
                                    name="websiteType"
                                    value="php"
                                />
                                <label for="typeChoice2">MEAN Stack</label>
                            </div>

                            <div>
                                <input
                                    type="radio"
                                    id="typeChoice3"
                                    name="websiteType"
                                    value="standardHtml"
                                />
                                <label for="typeChoice3">Standard HTML</label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="checkbox-container">
                    <fieldset>
                        <legend>Website Features</legend>
                        <div>
                            <div>
                                <input
                                    type="checkbox"
                                    id="checkbox1"
                                    name="websiteFeature"
                                    value="contactForm"
                                />
                                <label for="checkbox1">Contact Form</label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    id="checkbox2"
                                    name="websiteFeature"
                                    value="inventoryManagement"
                                />
                                <label for="checkbox2">Inventory Management</label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    id="checkbox3"
                                    name="websiteFeature"
                                    value="budgetForm"
                                />
                                <label for="checkbox3">Budget Form</label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    id="checkbox4"
                                    name="websiteFeature"
                                    value="ecomCheckout"
                                />
                                <label for="checkbox4">E-commerce Checkout</label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <label for="pricesSelect">Timing and Prices</label>
                <select name="prices" id="pricesSelect">
                    <option value="">--Please choose an option--</option>
                    <option value="twoWeeks">Two Weeks - $5000</option>
                    <option value="fourWeeks">One Month - $2500</option>
                    <option value="twelveWeeks">Three Months - $1000</option>
                </select>
                <label for="additionalRequests">Additional Requests</label>
                <textarea
                    id="additionalRequests"
                    name="additionalRequests"
                    rows="5"
                    cols="33"
                ></textarea>
                <button type="submit">Order Now</button>
            </form>
        </div>
    </div>);
}

export default Order;