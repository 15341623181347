import './splash.css';
import Logo from '../../Assets/Images/CompanyLogo.png';

const Splash = () => {
    return (<div className="launch-page">
    <a href="about.html" className="launch-title">
      <h1>Martingale Stochastic Process LLC</h1>
    </a>
    
    <a href="about" className="launch-logo-link">
        <img
          className="logo"
          src={Logo}
          alt="Company logo."
          height="300"
          width="300"
        />
    </a>
    </div>);
}

export default Splash;